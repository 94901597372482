<template>
  <div class="main-content">
    <div class="header">
      <div class="container-fluid">
        <!-- Body -->
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="header-pretitle">
                {{ $t("menu.dashboard") }}
              </h6>
              <h1 class="header-title">
                {{ $t("locations.title") }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-container fluid>
      <b-row>
        <b-col cols="12">
          <div class="card">
            <div class="card-header">
              <!-- Title -->
              <h4 class="card-header-title"></h4>              
              <form class="mr-3">
                <Select2
                  v-model="perPage"
                  :options="perPageOptions"
                  :settings="{
                    minimumResultsForSearch: -1,
                    containerCssClass:
                    'custom-select custom-select-sm form-control-flush',
                    dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                  }"
                  @change="paginatedLocationList"                         
                />
              </form>
              <!-- Select -->
              <form class="mr-3">
                <Select2
                  v-model="sortOrder"
                  :options="sortOrderOptions"
                  :settings="{
                    minimumResultsForSearch: -1,
                    containerCssClass:
                      'custom-select custom-select-sm form-control-flush',
                    dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                  }"                         
                />                
              </form>

              <!-- Button -->
              <b-button
                v-if="companiesList.length"
                variant="primary"
                size="sm"
                @click="createLocation"
                >{{ $t("locations.create") }}</b-button
              >
            </div>
            <div class="card-header">
              <!-- Form -->
              <form>
                <div class="input-group input-group-flush input-group-merge">
                  <!-- Input -->

                  <b-form-input
                    :placeholder="$t('common.search')"
                    v-model="filter"
                    class="form-control form-control-prepended search"
                    type="search"
                  ></b-form-input>

                  <!-- Prepend -->
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <span class="fe fe-search"></span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="card-body">
              <b-pagination
                v-model="currentPage"                                
                :total-rows="locationsList.length"
                :per-page="perPage"
                aria-controls="my-table"
                class="pb-2"
              ></b-pagination>
              <zs-list-transition :showLoader="showLoader">
                <template v-slot:content>
                  <!-- v-for="location in locationsList" -->
                  <location-list-item
                    :location="location"
                    v-for="location in paginatedLocationList"
                    v-bind:key="location.Id"
                  />
                </template>
                <template v-slot:empty>
                  <empty-list
                    :title="
                      getUserCompanies.length
                        ? $t('locations.no_locations')
                        : $t('companies.no_companies')
                    "
                  >
                    <template v-slot:button v-if="!getUserCompanies.length"
                      ><b-button variant="primary" @click="createCompany">
                        {{ $t("companies.create") }}
                      </b-button></template
                    >
                  </empty-list>
                </template>
              </zs-list-transition>
              <zs-image-upload-modal @imageUploaded="updateLocationImage" />
              <b-pagination
                v-model="currentPage"                                
                :total-rows="locationsList.length"
                :per-page="perPage"
                aria-controls="my-table"
                class="pb-2"
              ></b-pagination>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import modalMixin from "@/mixins/modalMixin";
import ImageUploadModal from "@/components/modals/ImageUpload";
import {
  LOCATIONS_GET_ALL_LOCATIONS,
  LOCATION_CLEAR_ACTIVE,
} from "@/store/types/locations";
import { COMPANY_CLEAR_ACTIVE } from "@/store/types/companies";
import { M_COMPANY_FORM, M_LOCATION_FORM } from "@/components/modals/types";
import { definedKeyRolesIdsArray } from "@/acl/roles";
import ListTransition from "@/components/common/ListTransition";
import LocationListItem from "@/components/location/LocationListItem";
import EmptyList from "@/components/common/EmptyList";
import Select2 from "@/components/common/Select2";
import {    
    LOCATION_PATCH,
    LOCATION_GET,
} from "@/store/types/locations";

export default {
  mixins: [modalMixin],
  data() {
    return {
      filter: "",
      sortOrder: 1,
      sortOrderOptions: [ 
        { id: 1, text: "Asc"},
        { id: 2, text: "Desc"},
      ],
      showLoader: true,
      selectedCompanies: [],
      perPage: 20,   
      currentPage: 1,
      perPageOptions: [
        { id: 10, text: "10 " + this.$t("menu.per_page") },
        { id: 20, text: "20 " + this.$t("menu.per_page") },
        { id: 50, text: "50 " + this.$t("menu.per_page") },
      ],
    };
  },
  computed: {
    ...mapGetters(["getLocationsList", "getUserCompanies"]),
    companiesList() {
      return this.getUserCompanies.map((item) => {
        return {
          ...item,
          ...{ disabled: item.LocationsCount == 0 },
        };
      });
    },
    locationsList() {
      return this.getLocationsList
        .filter((item) =>
          item.Name.toLowerCase().includes(this.filter.toLowerCase()) || item.Address.toLowerCase().includes(this.filter.toLowerCase())
        )
        .sort((i1, i2) => {
          if (this.sortOrder == 1) {
            return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
          } else {
            return i1.Name.toUpperCase() < i2.Name.toUpperCase() ? 1 : -1;
          }
        });
    },
    paginatedLocationList(){
      return this.locationsList.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    }
  },
  created() {
    this.$store.commit(COMPANY_CLEAR_ACTIVE);
    this.$store.commit(LOCATION_CLEAR_ACTIVE);
    this.$store
      .dispatch(LOCATIONS_GET_ALL_LOCATIONS)
      .then(() => (this.showLoader = false));
  },
  methods: {
    createCompany() {
      this.showModal(null, M_COMPANY_FORM);
    },

    createLocation() {
      this.showModal(null, M_LOCATION_FORM);
    },

    updateLocationImage(response) {
      this.location = this.$session.get("location");
            this.$store
                .dispatch(LOCATION_PATCH, {
                    ...this.location,
                    ...{ ImageId: response.Id },
                })
                .then(() => {
                    this.$store.dispatch(
                        LOCATION_GET,
                        this.location.Id
                    );
                })
                .catch((error) => {});
        },
  },
  components: {
    LocationListItem,
    EmptyList,
    "zs-list-transition": ListTransition,
    Select2,
    "zs-image-upload-modal": ImageUploadModal,
  },
};
</script>
<!-- <style>
.select2-container--default .select2-results > .select2-results__options {
  overflow: hidden;
}
</style> -->
