<template>
    <b-list-group-item>
        <b-row class="align-items-center" style="height: 100px;">
            <b-col class="col-auto">
                <!-- Avatar -->
                <router-link
                    :to="{
                        name: 'location',
                        params: {
                            locationId: location.Id,
                            companyId: location.CompanyId,
                        },
                    }"
                    class="avatar avatar-lg"
                >
                    <img
                        :src="
                            location.SmallImageUrl ||
                            '/images/zesec-placeholder.png'
                        "
                        :alt="location.Name"
                        class="avatar-img rounded"
                    />
                </router-link>
            </b-col>
            <div class="col ml-n2">
                <!-- Title -->
                <h4 class="mb-1 name">
                    <router-link
                        :to="{
                            name: 'location',
                            params: {
                                locationId: location.Id,
                                companyId: location.CompanyId,
                            },
                        }"
                        >{{ location.Name }}</router-link
                    >
                </h4>

                <!-- Text -->
                <p class="card-text small text-muted mb-1">
                    {{
                        $t("common.count_doors", {
                            count: location.DevicesCount,
                        })
                    }}
                </p>
                <!-- count: location.UsersWithKeysCount, -->
                <p class="card-text small text-muted mb-1" style="line-height: 0.5;">
                    {{
                        $t("common.count_users", {
                            count: location.UsersCount,
                        })
                    }}
                </p>  
                <!-- <p class="card-text small text-muted mb-1" style="line-height: 0.5;" v-if="location.Id">
                    {{
                        $t("common.count_users", {
                            count: userCount(location.Id),
                        })
                    }}
                    
                </p> -->
                <!-- Time -->
                <p class="card-text small text-muted">
                    {{ location.Address }}
                </p>
            </div>

            <div class="col-auto">
                <!-- Dropdown -->
                <b-dropdown
                    variant="outline"
                    toggle-class="dropdown-ellipses dropdown-toggle"
                    no-caret
                    right
                    toggle-tag="a"
                    v-if="$can('manage', location) && !roleFlag"
                >
                    <template v-slot:button-content>
                        <!-- <i class="fe fe-more-vertical"></i> -->
                        <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                    </template>
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="editLocation(location)"
                    >
                        {{ $t("buttons.edit") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="deleteLocation(location)"
                    >
                        {{ $t("buttons.delete") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="locationImage(location)"
                    >
                        {{ $t("locations.image") }}
                    </b-dropdown-item>
                </b-dropdown>
                <b-dropdown
                    variant="outline"
                    toggle-class="dropdown-ellipses dropdown-toggle"
                    no-caret
                    right
                    toggle-tag="a"
                    v-if="roleFlag && (userRole != 'LocationManager' || userRole != 'KeyManager')"
                >
                    <template v-slot:button-content>
                        <!-- <i class="fe fe-more-vertical"></i> -->
                        <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                    </template>
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="editLocation(location)"
                    >
                        {{ $t("buttons.edit") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="deleteLocation(location)"
                    >
                        {{ $t("buttons.delete") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="locationImage(location)"
                    >
                        {{ $t("locations.image") }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </b-row>
        <!-- <zs-image-upload-modal @imageUploaded="updateLocationImage" /> -->
    </b-list-group-item>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import ImageUploadModal from "@/components/modals/ImageUpload";
import { M_LOCATION_FORM, M_LOCATION_DELETE, M_IMAGE_UPLOAD } from "@/components/modals/types";
import { IMAGE_TYPE_LOCATION } from "@/handlers/const";
import {    
    LOCATION_PATCH,
    LOCATION_GET,
} from "@/store/types/locations";
import { LOCATION_MEMBERS_GET } from "@/store/types/members";

export default {
    name: "location-list-item",
    data() {
        return {
            locationMembersList: [],
            count: 0,
            userRole: "",
            roleFlag: true,
        }
    },
    mixins: [modalMixin],
    props: ["location"],  
    created(){
        this.userRole = this.$session.get("userRole")
        // || this.userRole == "LocationManager" || this.userRole == "KeyManager"
        if( this.userRole == "CompanyOwner" || this.userRole == "CompanyManager" ){
            this.roleFlag = true
        }else{
            this.roleFlag = false
        }
    },
    methods: {
        userCount(id){
            this.$store
                .dispatch(LOCATION_MEMBERS_GET, id)
                .then((response) => {
                    this.count = response.length
                });
                return this.count 
        },
        editLocation(location) {
            this.showModal({ location: location }, M_LOCATION_FORM);
        },
        deleteLocation(location) {
            this.showModal({ location: location }, M_LOCATION_DELETE);
        },
        locationImage(location) {
            this.$session.set("locationId", location.Id);
            this.$session.set("location",this.location)
            this.showModal(
                {
                    image: {
                        url:
                            this.location.MediumImageUrl ||
                            "/images/zesec-placeholder.png",
                        type: IMAGE_TYPE_LOCATION,
                    },
                },
                M_IMAGE_UPLOAD
            );
        },
        updateLocationImage(response) {
            this.location = this.$session.get("location");
            this.$store
                .dispatch(LOCATION_PATCH, {
                    ...this.location,
                    ...{ ImageId: response.Id },
                })
                .then(() => {
                    this.$store.dispatch(
                        LOCATION_GET,
                        this.location.Id
                    );
                })
                .catch((error) => {});
        },
    },
    components: {
       // "zs-image-upload-modal": ImageUploadModal,
    },
};
</script>
<style >
.mw-48 {
    min-width: 48px;
}

.list-group-item:last-child{
  overflow-y: auto !important;
}

.btn:focus{
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
</style>
